<template>
  <b-card :title="this.title">
    <hr />
    <div class="table-responsive table-responsive-sm">
      <table class="table" >
        <thead>
          <tr class="justify-content-between px-1 pt-1 pb-2">
            <th scope="col"
              style="text-transform: capitalize; font-size: 1.15rem; line-height: 1.2; white-space: nowrap; text-align: left;">
              {{ t('home_column_name') }}
            </th>
            <th scope="col"
              style="text-transform: capitalize; font-size: 1.15rem; line-height: 1.2; white-space: nowrap; text-align: left;">
              {{ t('home_column_import') }}
            </th>
            <th scope="col"
              style="text-transform: capitalize; font-size: 1.15rem; line-height: 1.2; white-space: nowrap; text-align: left;">
              {{ t('home_column_cfdi') }}
            </th>
            <th scope="col"
              style="text-transform: capitalize; font-size: 1.15rem; line-height: 1.2; white-space: nowrap; text-align: center;">
              {{ t('Actions') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="this.data && this.data.length > 0">
          <tr v-for="(item, index) in this.data.slice(0, 5)" :key="index" md="auto"
            class="justify-content-between px-1 pb-1 overflow-auto">
            <td
              style="max-height: 3.6rem; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;">
              <span :id="`row-${item.name}-tc`" class="text-left pr-2">{{ item.name.length > 32 ? item.name.substr(0, 32)
                + " ..." : item.name
              }}</span>
              <b-tooltip v-if="(item.name.length > 32)" :title="item.name" class="cursor-pointer"
                :target="`row-${item.name}-tc`" />
            </td>
            <td>
              <span
                style=" white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; width: 150px;">$
                {{ formatPrice(item.total) }}</span>
            </td>
            <td class="text-center">
              <b-link class="align-text-top text-capitalize link-underline"
                :to="{ path: `/fg/cfdi/list?taxid=${item.taxid}` }">{{ item.cfdi }}</b-link>
            </td>
            <td class="text-center">
              <feather-icon :id="`contact-row-${item.uuid}-invoice-icon`" icon="FileTextIcon" class="cursor-pointer"
                size="16" @click="addCfdiFromContact(item.uuid)" />
              <b-tooltip :title="t('invoiceit')" class="cursor-pointer"
                :target="`contact-row-${item.uuid}-invoice-icon`" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-card>
</template>
<script>
import {
  BTabs,
  BTab,
  BCard,
  BCol,
  BLink,
  BTooltip,
  BContainer,
  BListGroup,
  BListGroupItem,
  BRow,
} from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
export default {
  props: ["data", "title"],
  components: {
    BTabs,
    BTab,
    BTooltip,
    BCard,
    BLink,
    BRow,
    BContainer,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  methods: {
    addCfdiFromContact(uuid) {
      this.$router.replace({ name: 'fg-cfdi-add-from-contact', params: { cuuid: uuid } })
        .then(() => {

        })
    },
    roundNumber(number, decimalPlaces) {
      return number.toFixed(decimalPlaces);
    },
    getCurrentAmount() {
      return `$ ${this.formatPrice(this.chartData[0].total)}`;
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
  setup() {
    /** i18n utils */
    const { t } = useI18nUtils();
    return {
      t,
    };
  },
  data() {
    return {
      options: {},
    };
  },
};
</script>
