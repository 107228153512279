<template>
    <b-card style="max-height: 250px;">
        <template #header>
            <div class="d-flex justify-content-between align-items-center">
                <h4 class="card-title m-0" style="padding-left: 16px; font-size: 1.2rem;">{{ t('home_latest_product') }}</h4>
            </div>
        </template>
        <b-row no-gutters v-if="this.lastproduct && Object.keys(this.lastproduct).length > 0">
            <b-col md="auto" style="padding-right: 16px;">
                <div class="icon-container">
                    <feather-icon icon="BoxIcon" size="32" style="color: #ff9f43;" />
                </div>
            </b-col>
            <b-col>
                <span :id="`hlp-tag-tp`" class="title">{{
                    this.lastproduct['name'].length > 32 ? this.lastproduct['name'].substr(0, 32) + " ..." :
                    this.lastproduct['name']
                }}</span>
                <b-tooltip v-if="(this.lastproduct['name'].length > 26)" :title="this.lastproduct['name']"
                    target="hlp-tag-tp" />
                <br />
                <div class="row">
                    <div class="col">
                        <span class="text">{{ t('home_added_on_label') }} </span>
                        <span class="date">{{ getDate() }}</span>
                    </div>
                    <div class="col-2">
                        <feather-icon :id="`product-b-1-invoice-icon`" icon="FileTextIcon" class="cursor-pointer" size="24"
                         @click="addCfdiFromProduct" />
                        <b-tooltip :title="t('invoiceit')" class="cursor-pointer" :target="`product-b-1-invoice-icon`" />
                    </div>                    
                </div>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col md="auto" style="padding-right: 16px;">
                <div class="icon-container" style="background-color: #FFCFDB;">
                    <feather-icon icon="AlertTriangleIcon" size="28" style="color: #FF124B;" />
                </div>
            </b-col>
            <b-col>
                <span class="title">{{ t('home_error_last_product_header') }}</span><br />
                <div class="row">
                    <div class="col">
                        <span class="text">{{ t('home_error_last_product_label') }}</span>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BTabs, BContainer, BCard, BTooltip, BRow, BCol } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { DateTime } from 'luxon';

export default {
    props: ["lastproduct"],
    components: {
        BTabs,
        BContainer,
        BTooltip,
        BCard,
        BRow,
        BCol,
    },
    methods: {
        addCfdiFromProduct() {
            this.$router.replace({ name: 'fg-cfdi-add-from-product', params: { puuid: this.lastproduct['uuid'] } })
                .then(() => {

                })
        },
        getDate() {
            let today = DateTime.now();
            let target = DateTime.fromISO(this.lastproduct['cdate']);
            let yesterday = DateTime.now().minus({ days: 1 });

            if (today.hasSame(target, 'day')) return this.t('today');
            if (yesterday.hasSame(target, 'day')) return this.t('yesterday');

            return target.toFormat('dd/MM/yyyy');
        },

        getColor(color) {
            return getComputedStyle(document.querySelector('body')).getPropertyValue(color);
        },

    },
    setup() {
        /** i18n utils */
        const { t } = useI18nUtils();
        return {
            t,
        };
    },
    data() {
        return {
            options: {},
        };
    },
};
</script>

<style scoped>
.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #fff5db;
}

.icon-container .text-white {
    font-size: 32px;
}

.title {
    /*font-weight: bold;*/
    font-size: 1.00rem;
}

.amount {
    font-weight: bold;
}

.date {
    font-weight: bold;
}

.text {}
</style>
  