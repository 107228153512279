<template>
  <b-card :title="title">
    <div class="d-flex">
      <vue-echarts :options="chart" class="chart-container" :autoresize="true"></vue-echarts>
    </div>
  </b-card>
</template>
<script>
import { BTabs, BTab, BContainer, BCol, BCard, BRow } from "bootstrap-vue";
import vueEcharts from "vue-echarts";
import * as echarts from "echarts";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
export default {
  props: ["chartData", "title", "months"],
  components: {
    BTabs,
    BTab,
    BCol,
    BContainer,
    BCard,
    BRow,
    vueEcharts,
  },
  watch: {
    months: function (newVal, oldVal) {
      this.processData();
    },
    // chartData: function (n, o) {
    //   this.processData();
    // }
  },
  methods: {
    roundNumber(number, decimalPlaces) {
      return number.toFixed(decimalPlaces);
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    formatText(params) {
      var data = params['data'];
      return `$${this.formatPrice(data['value'])}`
    },

    processData() {
      let primaryColor = getComputedStyle(document.querySelector('body')).getPropertyValue('--purple');
      let textColor = getComputedStyle(document.querySelector('body')).getPropertyValue('color');

      var df = [];
      var month = [];

      var limit = 6;
      //console.log(this.chartData)

      // loops 
      const chartData = this.chartData.sort((a,b) => a.month - b.month);
      for (const {month: mth, total} of chartData) {
        df.push({
            name: this.months[mth - 1], 
            value: this.roundNumber(parseFloat(total), 2),
          });

          month.push(this.months[mth - 1]);
      }

      this.chart = {
        grid: {
          top: '2%',
        },
        xAxis: {
          type: 'category',
          data: month,
          axisTick: {
            show: false,
            alignWithLabel: true
          },
          minorTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: textColor
            }
          },
          axisLabel: {
            interval: 0,
            rotate: -45,
            textStyle: {
              color: textColor,
              fontWeight: '400'
            },
          },
          minInterval: 1,
        },
        tooltip: {
          trigger: 'item',
          formatter: this.formatText
        },
        yAxis: {
          type: 'value',
          scale: false,

          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          minorTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: textColor
            }
          },
          axisLabel: {
            textStyle: {
              color: textColor,
              fontWeight: '400'
            }
          }
        },
        series: [
          {
            type: "bar",
            data: df,
            itemStyle: {
              normal: {
                color: primaryColor,
                barBorderRadius: [8, 8, 8, 8]
              }
            },
            barWidth: 18,
            barCategoryGap: '1%',
            barGap: '10%'
          },
        ],
      };
    },
  },
  setup() {
    /** i18n utils */
    const { t } = useI18nUtils();
    return {
      t,
    };
  },
  beforeMount() {
    this.processData(this.chartData);
  },
  data() {
    return {
      options: {},
    };
  },
};
</script>
<style scoped>
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #fff5db;
}

.title {
  font-weight: bold;
  font-size: 1.4rem;
}

.chart-container {
  padding-right: 16px;
  width: 100%;
  height: 375px;
}
</style>
