<template>
    <b-card :title="this.title" style="max-width: 800px; min-width: 530px; max-height: 250px;">

        <b-row no-gutters v-if="chartData && chartData.length > 0">
            <b-col md="4">
                <vue-echarts :options="chart"></vue-echarts>
            </b-col>
            <b-col md="6" style="padding-top: 10px; padding-left: 32px">
                <div>
                    <span style="font-weight: bold; font-size: 1.6rem">{{ t("home_current_month") }}</span><br />
                    <span style="font-size: 1.45rem">{{ this.getCurrentAmount() }}</span><br /><br />
                    <span style="font-size: 1.1rem; font-weight: bold;">{{ this.getPercentageDiff() }}% </span>
                    <span style="font-size: 1.1rem;">{{ this.getPercentageDiff() > 0 ?
                        t('home_positive_progress') : t('home_negative_progress') }}</span>
                </div>
            </b-col>
        </b-row>


        <b-row class="align-items-center" v-else>
            <b-col md="auto">
                <div class="icon-container" style="background-color: #FFCFDB;">
                    <feather-icon icon="AlertTriangleIcon" size="28" style="color: #FF124B;" />
                </div>
            </b-col>
            <b-col>
                <span class="title">{{ t('home_error_chart') }}</span>
            </b-col>
        </b-row>

    </b-card>
</template>

<script>
import { BTabs, BContainer, BCard, BRow, BCol } from "bootstrap-vue";
import vueEcharts from "vue-echarts";
import * as echarts from "echarts";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
    props: ["chartData", "title", "months"],
    components: {
        BTabs,
        BContainer,
        BCard,
        BRow,
        BCol,
        vueEcharts,
    },
    watch: {
        months: function (newVal, oldVal) {
            this.processData();
        },
        chartData: function (n, o) {
            this.processData();
        }
    },
    methods: {

        getCurrentAmount() {
            return `$ ${this.formatPrice(this.chartData[0].total)}`;
        },

        getPercentageDiff() {
            let a = parseFloat(this.chartData[0].total);
            let b = parseFloat(this.chartData[1].total);
            let diff = a - b;
            let ps = diff / b;
            let psf = ps * 100;
            return this.roundNumber(psf, 2);
        },

        roundNumber(number, decimalPlaces) {
            return number.toFixed(decimalPlaces);
        },

        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        formatText(params) {
            //console.log(params);
            return `${params.data['name']}  $${this.formatPrice(params.data['value'])}`
        },

        processData() {
            var df = [];

            for (var item in this.chartData.slice(-2)) {
                df.push({ name: this.months[this.chartData[item].month - 1], value: this.roundNumber(parseFloat(this.chartData[item].total), 2) });
            }

            //console.log(df)

            this.chart = {
                width: 180,
                height: 164,
                tooltip: {
                    trigger: 'item',
                    formatter: this.formatText
                },
                series: [
                    {
                        type: "pie",
                        data: df,
                        radius: [40, 80],
                        animation: false,
                        boundaryGap: false,
                        color: ['#1bb378', '#caf7e6'],
                        label: {
                            show: false
                        },
                        labelLine: {
                            show: false
                        },
                        graphic: {
                            type: 'text',
                            left: 'center',
                            top: 'center',
                            style: {
                                text: 'My Label',
                                font: 'bold 8px',
                                fill: '#FAFAFA'
                            }
                        }
                    }
                ]
            };
        },
    },
    setup() {
        /** i18n utils */
        const { t } = useI18nUtils();
        return {
            t,
        };
    },
    beforeMount() {
        this.processData(this.chartData);
    },
    data() {
        return {
            options: {},
        };
    },
};
</script>
<style scoped>
.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #fff5db;
}

.title {
    font-weight: bold;
    font-size: 1.4rem;
}
</style>