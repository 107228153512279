<template>
    <b-card style="max-height: 250px;">
        <template #header>
            <div class="d-flex justify-content-between align-items-center">
                <!--<feather-icon :id="`cfdi-row-12-invoice-duplicate`" icon="CopyIcon" class="cursor-pointer" size="24"
                    @click="duplicateCfdi" />
                <b-tooltip :title="t('duplicate')" class="cursor-pointer" :target="`cfdi-row-12-invoice-duplicate`" />-->
                <h4 class="card-title m-0" style="padding-left: 16px;font-size: 1.2rem;">{{ t('home_latest_billing') }}</h4>

            </div>
        </template>
        <b-row no-gutters v-if="this.lastcfdi && Object.keys(this.lastcfdi).length > 0">
            <b-col md="auto" style="padding-right: 16px;">
                <div class="icon-container">
                    <feather-icon icon="DollarSignIcon" size="32" style="color: #1bb378;" />
                </div>
            </b-col>
            <b-col>
                <span :id="`hlb-tag-tp`" class="title">{{
                    this.lastcfdi['name'].length > 32 ? this.lastcfdi['name'].substr(0, 32) + " ..." :
                    this.lastcfdi['name']
                }}</span>
                <b-tooltip v-if="(this.lastcfdi['name'].length > 26)" :title="this.lastcfdi['name']" target="hlb-tag-tp" />
                <div class="row">
                    <div class="col" style="white-space: nowrap;">
                        <span class="text">{{ t('home_billed_on_label') }}</span>
                        <span class="date"> {{ getDate() }}</span>
                    </div>
                    <!--<div class="col">
                        <div class="col">
                            <span class="amount">{{ getCurrentAmount() }}</span>
                        </div>
                    </div>-->
                    <div class="col-2">
                        <feather-icon :id="`cfdi-row-12-invoice-duplicate`" icon="CopyIcon" class="cursor-pointer" size="24"
                            @click="duplicateCfdi" />
                        <b-tooltip :title="t('duplicate')" class="cursor-pointer" :target="`cfdi-row-12-invoice-duplicate`" />
                    </div>                    
                </div>

            </b-col>
        </b-row>
        <b-row v-else>
            <b-col md="auto" style="padding-right: 16px;">
                <div class="icon-container" style="background-color: #FFCFDB;">
                    <feather-icon icon="AlertTriangleIcon" size="28" style="color: #FF124B;" />
                </div>
            </b-col>
            <b-col>
                <span class="title">{{ t('home_error_last_cfdi_header') }}</span><br />
                <div class="row">
                    <div class="col">
                        <span class="text">{{ t('home_error_last_cfdi_label') }}</span>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BTabs, BContainer, BCard, BRow, BCol, BTooltip } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { DateTime } from 'luxon';

export default {
    props: ["lastcfdi"],
    components: {
        BTabs,
        BTooltip,
        BContainer,
        BCard,
        BRow,
        BCol,
    },
    methods: {
        duplicateCfdi() {
            this.$router.replace({ name: 'fg-cfdi-duplicate', params: { duuid: this.lastcfdi['uuid'] } })
                .then(() => {

                })
        },
        getDate() {
            let today = DateTime.now();
            let target = DateTime.fromISO(this.lastcfdi['date']);
            let yesterday = DateTime.now().minus({ days: 1 });

            if (today.hasSame(target, 'day')) return this.t('today');
            if (yesterday.hasSame(target, 'day')) return this.t('yesterday');

            return target.toFormat('dd/MM/yyyy');
        },

        getColor(color) {
            return getComputedStyle(document.querySelector('body')).getPropertyValue(color);
        },

        getCurrentAmount() {
            return `$ ${this.formatPrice(this.lastcfdi['amount'])}`;
        },

        getPercentageDiff() {
            let a = this.chartData[0].total;
            let b = this.chartData[1].total;
            return this.roundNumber(((a * 100) / b), 2);
        },

        roundNumber(number, decimalPlaces) {
            return number.toFixed(decimalPlaces);
        },

        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        formatText(params) {
            //console.log(params);
            return `${params.data['name']}  $${this.formatPrice(params.data['value'])}`
        },
    },
    setup() {
        /** i18n utils */
        const { t } = useI18nUtils();
        return {
            t,
        };
    },
    data() {
        return {
            options: {},
        };
    },
};
</script>

<style scoped>
.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #caf7e6;
}

.icon-container .text-white {
    font-size: 32px;
}

.title {
    font-size: 1.00rem;
    /*font-weight: bold;*/
}

.amount {
    font-weight: bold;
}

.date {
    font-weight: bold;
}

.ml-auto {
    margin-left: auto !important;
}

.text {}
</style>
  