<template>
    <b-card style="max-height: 250px;">
        <template #header>
            <div class="d-flex justify-content-between align-items-center">
                <h4 class="card-title m-0" style="padding-left: 16px; font-size: 1.2rem;">{{ t('home_latest_client') }}</h4>
            </div>
        </template>
        <b-row no-gutters v-if="this.lastclient && Object.keys(this.lastclient).length > 0">
            <b-col md="auto" style="padding-right: 16px;">
                <div class="icon-container">
                    <feather-icon icon="UsersIcon" size="32" style="color: #7367f0;" />
                </div>
            </b-col>
            <b-col>
                <div class="row">
                    <div class="col"><span :id="`hlc-tag-tp`" class="title">{{
                        this.lastclient['name'].length > 32 ? this.lastclient['name'].substr(0, 32) + " ..." :
                        this.lastclient['name']
                    }}</span>
                        <b-tooltip v-if="(this.lastclient['name'].length > 26)" :title="this.lastclient['name']"
                            target="hlc-tag-tp" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="text">{{ t('home_added_on_label') }} </span>
                        <span class="date">{{ getDate() }}</span>
                    </div>
                    <div class="col-2">
                        <feather-icon :id="`contact-b-1-invoice-icon`" icon="FileTextIcon" class="cursor-pointer" size="24"
                        @click="addCfdiFromContact" />
                        <b-tooltip :title="t('invoiceit')" class="cursor-pointer" :target="`contact-b-1-invoice-icon`" />
                    </div>
                </div>                
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BTabs, BContainer, BTooltip, BCard, BRow, BCol } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { DateTime } from 'luxon';

export default {
    props: ["lastclient"],
    components: {
        BTabs,
        BContainer,
        BCard,
        BRow,
        BTooltip,
        BCol,
    },
    methods: {
        addCfdiFromContact() {
            this.$router.replace({ name: 'fg-cfdi-add-from-contact', params: { cuuid: this.lastclient.taxid } })
                .then(() => {

                })
        },
        getDate() {
            let today = DateTime.now();
            let target = DateTime.fromISO(this.lastclient['cdate']);
            let yesterday = DateTime.now().minus({ days: 1 });

            if (today.hasSame(target, 'day')) return this.t('today');
            if (yesterday.hasSame(target, 'day')) return this.t('yesterday');

            return target.toFormat('dd/MM/yyyy');
        },
        getColor(color) {
            return getComputedStyle(document.querySelector('body')).getPropertyValue(color);
        },

    },
    setup() {
        /** i18n utils */
        const { t } = useI18nUtils();
        return {
            t,
        };
    },
    data() {
        return {
            options: {},
        };
    },
};
</script>

<style scoped>
.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #ededfd;
}

.icon-container .text-white {
    font-size: 32px;
}

.title {
    /*font-weight: bold;*/
    font-size: 1.0rem;
}

.amount {
    font-weight: bold;

}

.date {

    font-weight: bold;
}

.text {}
</style>
  