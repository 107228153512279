<template>
  <div>
    <b-container style="margin-left: 0; padding-left: 0; justify-content: flex-start; text-align: left;"
      v-if="!this.loading" fluid>
      <b-row>
        <b-col>
          <home-list-clients :data="homeData['clients']" :title="getClientHeader"></home-list-clients>
        </b-col>
        <b-col>
          <home-list-products :data="homeData['products']" :title="getProductsHeader"></home-list-products>
        </b-col>
      </b-row>
      <div class="row">
        <b-col md="6" lg="6" class="col">
          <home-annual-chart :chart-data="chartData" :title="getAnualCFDITitle" :months="getMonths"></home-annual-chart>
        </b-col>
        <b-col>
          <home-last-bill-vue :lastcfdi="homeData['latest_cfdi']"></home-last-bill-vue>
          <home-last-client :lastclient="homeData['latest_client']"></home-last-client>
          <home-last-product :lastproduct="homeData['latest_product']"></home-last-product>
        </b-col>
      </div>
      <!-- <b-row>
        <b-col md="6" lg="6" class="col">
          <home-chart-last-month :chart-data="chartData" :title="getBillingTitle"
            :months="getMonths"></home-chart-last-month>
        </b-col>
      </b-row> -->
    </b-container>
    <b-container v-else>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </b-container>
  </div>
</template>
<script>
import { BTabs, BContainer, BCol, BRow } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import HomeListClients from "./HomeListClients.vue";
import HomeListProducts from "./HomeListProducts.vue";
import HomeAnnualChart from "./HomeAnnualChart.vue";
import HomeChartLastMonth from "./HomeChartLastMonth.vue";
import HomeLastProduct from "./HomeLastProduct.vue";
import { t } from "@/@core/libs/i18n/utils";
import HomeLastBillVue from './HomeLastBill.vue';
import HomeLastClient from './HomeLastClient.vue';
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BTabs,
    BContainer,
    BCol,
    BRow,
    HomeChartLastMonth,
    HomeListClients,
    HomeListProducts,
    HomeAnnualChart,
    HomeLastProduct,
    HomeLastClient,
    HomeLastBillVue
  },
  setup() {
    /** i18n utils */
    const { t } = useI18nUtils();
    return {
      t,
    };
  },
  computed: {
    getClientHeader() {
      return t("home_top5clients");
    },
    getProductsHeader() {
      return t("home_top5products");
    },

    getMonths() {
      return t("months");
    },

    getAnualCFDITitle() {
      return t("home_anual_cfdi")
    },

    getBillingTitle() {
      return t("home_current_billing")
    }
  },
  data() {
    return {
      loading: true,
      options: {},
      homeData: {},
      chartData: [],
    };
  },
  beforeMount() {
    this.getHomeInfo();
  },
  methods: {
    redirectToContacts() {
      this.$router.replace({ name: 'fg-contact-list' })
        .then(() => {
        })
    },
    changeLoading(value) {
      this.loading = value;
    },
    getHomeInfo() {
      this.changeLoading(true);

      // const notification = this.$route.query.notification;
      // const collection_id = this.$route.query.collection_id;
      // const collection_status = this.$route.query.collection_status;
      // const param2 = this.$route.query.status;
      // const param2 = this.$route.query.external_reference;
      this.$http.post("/home/get", {}).then((response) => {
        this.homeData = response["data"];
        const charData = [];
        for (let index = 1; index <= 12; index++) {
          charData.push(response['data'].anual_data.find(e => e.month === index) || {
            month: index,
            total: "0"
          })
        }
        this.chartData = charData;
        if (this.chartData.length === 0) {
          this.redirectToContacts();
        }
/*
        this.$http.post("/s/change", { i: JSON.parse(localStorage.getItem('tokenBundle')).uuid.split('').reverse().join('') }).then((response) => {
          const { session, tokenBundle } = response.data;
          const bundle = JSON.parse(localStorage.getItem('tokenBundle'));
          useJwt.setToken(session.accessToken);
          useJwt.setRefreshToken(session.refreshToken);
          localStorage.setItem("session", JSON.stringify(session));
          localStorage.setItem("tokenBundle", JSON.stringify(tokenBundle));
          // only when there have been any change
          if(bundle.count !== tokenBundle.count){
            location.reload();
          }
        });*/
        
      }).finally( () => {
        this.changeLoading(false);
      });
    },
  },
};
</script>
